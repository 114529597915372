/* imgsizer (flexible images for fluid sites) */
var imgSizer={Config:{imgCache:[],spacer:"/path/to/your/spacer.gif"},collate:function(aScope){var isOldIE=(document.all&&!window.opera&&!window.XDomainRequest)?1:0;if(isOldIE&&document.getElementsByTagName){var c=imgSizer;var imgCache=c.Config.imgCache;var images=(aScope&&aScope.length)?aScope:document.getElementsByTagName("img");for(var i=0;i<images.length;i++){images[i].origWidth=images[i].offsetWidth;images[i].origHeight=images[i].offsetHeight;imgCache.push(images[i]);c.ieAlpha(images[i]);images[i].style.width="100%";}
if(imgCache.length){c.resize(function(){for(var i=0;i<imgCache.length;i++){var ratio=(imgCache[i].offsetWidth/imgCache[i].origWidth);imgCache[i].style.height=(imgCache[i].origHeight*ratio)+"px";}});}}},ieAlpha:function(img){var c=imgSizer;if(img.oldSrc){img.src=img.oldSrc;}
var src=img.src;img.style.width=img.offsetWidth+"px";img.style.height=img.offsetHeight+"px";img.style.filter="progid:DXImageTransform.Microsoft.AlphaImageLoader(src='"+src+"', sizingMethod='scale')"
img.oldSrc=src;img.src=c.Config.spacer;},resize:function(func){var oldonresize=window.onresize;if(typeof window.onresize!='function'){window.onresize=func;}else{window.onresize=function(){if(oldonresize){oldonresize();}
func();}}}}

// add twitter bootstrap classes and color based on how many times tag is used
function addTwitterBSClass(thisObj) {
  var title = jQuery(thisObj).attr('title');
  if (title) {
    var titles = title.split(' ');
    if (titles[0]) {
      var num = parseInt(titles[0]);
      if (num > 0)
      	jQuery(thisObj).addClass('label label-default');
      if (num == 2)
        jQuery(thisObj).addClass('label label-info');
      if (num > 2 && num < 4)
        jQuery(thisObj).addClass('label label-success');
      if (num >= 5 && num < 10)
        jQuery(thisObj).addClass('label label-warning');
      if (num >=10)
        jQuery(thisObj).addClass('label label-important');
    }
  }
  else
  	jQuery(thisObj).addClass('label');
  return true;
}

jQuery(window).load(function(){
    setTimeout(function() {
      jQuery('#cover').fadeOut(1000);
      jQuery('body#no-scroll').removeAttr('id');
      // jQuery('.sub-title').css('opacity', 1);
      // jQuery('.fs-options > .g0:first-of-type .fs-option-label').text('Show All');
    },"1500");
});

function wDirections() {
  jQuery('.written-directions').slideToggle();
}

// as the page loads, call these scripts
jQuery(document).ready(function($) {
	
	$(window).on('scroll', function() {

		if ($(this).scrollTop() > 200) {
    		$('.sidenav').addClass('scrolled');
    	} else {
    		$('.sidenav').removeClass('scrolled');
    	}

	});
	
	$(window).load(function() {

		if ($(this).scrollTop() > 200) {
    		$('.sidenav').addClass('scrolled');
    	} else {
    		$('.sidenav').removeClass('scrolled');
    	}


	});

  // $(document).on('facetwp-refresh', function() {
  //     // FWP.facets['my_facet'] = [10, 20, 30]; // Change a facet value
  //  });

   $(document).on('facetwp-loaded', function() {
      $('.fs-options > .g0:first-of-type .fs-option-label').text('All');
      function fwp_redirect() {
        FWP.parse_facets();
      }
      // Scroll to the top of the page after the page is refreshed
      $('html, body').animate({ scrollTop: 900 }, 500);
  });

	// modify tag cloud links to match up with twitter bootstrap
	$("#tag-cloud a").each(function() {
	    addTwitterBSClass(this);
	    return true;
	});

	$("p.tags a").each(function() {
		addTwitterBSClass(this);
		return true;
	});

	$("ol.commentlist a.comment-reply-link").each(function() {
		$(this).addClass('btn btn-success btn-mini');
		return true;
	});

	$('#cancel-comment-reply-link').each(function() {
		$(this).addClass('btn btn-danger btn-mini');
		return true;
	});

	// $('article.post').hover(function(){
	// 	$('a.edit-post').show();
	// },function(){
	// 	$('a.edit-post').hide();
	// });

	// Prevent submission of empty form
	$('[placeholder]').parents('form').submit(function() {
	  $(this).find('[placeholder]').each(function() {
		var input = $(this);
		if (input.val() == input.attr('placeholder')) {
		  input.val('');
		}
	  });
	});

	$('.alert-message').alert();

	$('.dropdown-toggle').dropdown();

  if ($('body').hasClass('page-template-page-vacancies')) {
    $(function() {
      FWP.loading_handler = function(params) {
          params.element.find('.facetwp-checkbox').css('opacity', 0.5);
      }
     });
  }
  $('.jobs').owlCarousel({
      margin:0,
      nav:false,
      items:1
  });

  $('.box-slider').owlCarousel({
      margin:0,
      nav:false,
      items:1
  });

  function goBack() {
      window.history.go(-1);
  }

  $(".backbtn").click(function(){
    goBack();
  });

  var wvw = $(window).width();

  function MobileNav() {
    $(".mobtrigger").click(function(){
        $("#nav").toggleClass('active');
    });
  }

  if (wvw <=1000){
    MobileNav();
  } else {
    $(".mobtrigger").click(function(){
      $(".scroll-wrapper").toggleClass('open');
    });
  }

    var controller = new ScrollMagic.Controller();

    var postCount = $('.box').length;
    var min = 1;
    var max = 3;
    var random = Math.floor(Math.random() * (max - min + 1)) + min;

    var boxes = [];
    var count = 0;
    $(".box").each(function(){
        count = count+1;
        var box = ".box-" + count;
        var boxTrigger = ".boxTrigger-" + count;
        if (wvw <=1000){
           var speed = random;
           var positionFrom = "0";
           var positionTo = "0";
        } else {
          var speed = $(this).attr("data-speed");
          var positionFrom = $(this).attr("data-startposition");
          var positionTo = $(this).attr("data-endposition");
        }

        var scale_tween = TweenMax.fromTo(box, speed, {autoAlpha: 1, top: positionFrom}, {autoAlpha: 1,top: positionTo, ease: Linear.easeNone});

        var scale_scene = new ScrollMagic.Scene({
          triggerElement: boxTrigger,
          triggerHook: 0.8,
          offset: -203,
          duration: $("#content").height()
        })
        // .addIndicators() // add indicators (requires plugin)
        .setTween(scale_tween);

        controller.addScene([
          scale_scene,
        ]);

    });

  $(window).resize(function(){
      var wvw = $(window).width();

      if (wvw <= 800) {
        MobileNav();
      }

  });

    $('.option_A ul li:last-child input:last-child').attr("placeholder", "Any other White background").val('');
    $('.option_B ul li:last-child input:last-child').attr("placeholder", "Any other mixed / multiple ethnic background").val('');
    $('.option_C ul li:last-child input:last-child').attr("placeholder", "Any other Asian background – please specify").val('');
    $('.option_D ul li:last-child input:last-child').attr("placeholder", "Any other Black / African / Caribbean background – please specify").val('');
    $('.option_E ul li:last-child input:last-child').attr("placeholder", "Any other ethnic background – please specify").val('');

    $('.upload-cv .gform_button_select_files').val('Upload CV');
    $('.upload-doc .gform_button_select_files').val('Upload Document');

    $( "#accordion" ).accordion({
      heightStyle: "content",
      active: false,
      collapsible: true
    });

    if ($(".gform_wrapper").hasClass('gform_validation_error')) {
      $(".ui-accordion-content").addClass('open');
    }

    if ($("#gform_confirmation_wrapper_4").hasClass('gform_confirmation_wrapper')) {
      $(".ui-accordion-content").addClass('open');
    }

    var lastScrollTop = 100;
    var navPosition = $('#nav').offset();
    $(window).scroll(function(event){
       var st = $(this).scrollTop();
       if (st > lastScrollTop){
         $("#nav").removeClass('fixed')
       } else {
           $("#nav").addClass('fixed')
       }

       if (st < navPosition.top){
         $("#nav").removeClass('fixed')
       }
       lastScrollTop = st;
    });

    var boxWidth = $('.box-1 span.square-box').innerWidth();

    $('span.square-box').css('height', boxWidth);

    $(document).ready(function () {
      var scrollURL = window.location.href;
      var afHash = scrollURL.indexOf('#');
      var hash = '#'+scrollURL.substring(afHash + 1);
        if (scrollURL.indexOf(hash) > -1) {
          $("html, body").delay(2000).animate({
              scrollTop: $(hash).offset().top - 300
          }, 2000);
        }
    });

    $('.menu-main-nav-container').addClass('sm');

    $('#menu-main-nav').smartmenus();

});
